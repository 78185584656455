import {
  AaWebSdkController,
  AccountDiscoveryRequest,
  AccountLinkingRequest,
  AuthInit,
  AuthVerify,
  ConfirmLinkingRequest,
  ConsentRequest,
  MobileAuthInit,
  MobileAuthVerify,
} from "@finarkein/aasdk-core";
import axios, { AxiosInstance } from "axios";
import { useRouter } from "vue-router";
import axiosRetry from "axios-retry";

const http: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 60000,
  withCredentials: false,
});
axiosRetry(http, {
  retries: 3, // no of retries
  retryDelay: (retryCount) => {
    return retryCount * 1000; // time interval between retries
  },
  retryCondition: (error: any) => {
    // specify on which error we have to make these requests
    return [400, 500, 502, 503, 504].includes(error.response?.status);
  },
});

export default function useBackend() {
  const router = useRouter();

  const getWebviewDetails = (
    id: string,
    inputs = {},
    tenant?: string,
    redirectTo?: string,
    receivedAuth?: string,
  ) => {
    let backUrl = "";
    let backRoute;
    backRoute = router.resolve({
      name: "v2.thank-you",
      params: { identifier: id },
    });
    if (redirectTo) {
      backRoute = router.resolve({
        name: redirectTo,
        params: { identifier: id },
      });
    }
    backUrl = new URL(backRoute.href, window.location.origin).href;
    console.debug("[api] resolved redirection callback url to: " + backUrl);
    let endpoint = `/r/${id}/webview`;
    let headersObj = {};
    if (receivedAuth) {
      endpoint = `/o/r/${id}/webview`;
      headersObj = {
        Authorization: `Bearer ${receivedAuth}`,
      };
    }
    const data = {
      redirectUrl: backUrl,
      ...inputs, // override with any inputs supplied
    };
    return http.post(endpoint, data, { headers: headersObj });
  };

  const getMarkCompleteDetails = (id: string, inputs = {}, receivedAuth?: string) => {
    let endpoint = `/r/${id}/mark-complete`;
    let headersObj = {};
    if (receivedAuth) {
      endpoint = `/o/r/${id}/mark-complete`;
      headersObj = {
        Authorization: `Bearer ${receivedAuth}`,
      };
    }
    const data = {
      ...inputs, // override with any inputs supplied
    };
    return http.post(endpoint, data, { headers: headersObj });
  };

  const getConsentData = (request: any) => {
    const data = {
      ...request, // override with any inputs supplied
    };
    return http.post(`/v1/x/a`, data);
  };

  const createConsentRequest = (xid: string, request: any) => {
    const data = {
      ...request,
    };
    return http.post(`/v1/x/a/${xid}`, data);
  };

  const getConsentStatus = (requestId: string) => {
    return http.get(`/r/consent-status/${requestId}`);
  };

  const sendStatusUpdates = async (requestId: any, request: any) => {
    return http.post(`/r/journey/status/${requestId}`, request);
  };

  const getResponseParams = async (xid: any) => {
    return http.get(`/x/a/${xid}`);
  };

  const getBankList = (id: string, page = 0, size = 1000, receivedAuth?: string) => {
    let endpoint = `${process.env.VUE_APP_API_BASE}/r/${id}/fip?page=${page}&size=${size}`;
    let headersObj = {};
    if (receivedAuth) {
      endpoint = `${process.env.VUE_APP_API_BASE}/o/r/${id}/fip?page=${page}&size=${size}`;
      headersObj = {
        Authorization: `Bearer ${receivedAuth}`,
      };
    }
    return http
      .get(
        endpoint, { headers: headersObj },
      )
      .then((response) => {
        // const data = response.data;
        // if (!data["anubhavConfig"]) {
        //   data["anubhavConfig"] = {
        //     fipSelectEnabled: false,
        //     fipSelectType: "single", //single/multiple
        //   };
        // }
        return response;
      });
  };

  const getChildRequestIds = (parentId: string, accessToken: string): Promise<string[]> => {
    const endpoint = `${process.env.VUE_APP_API_BASE}/o/cr/${parentId}`;
    return http.get(endpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((response: any) => {
      if (response.data["requestIds"]) {
        return response.data["requestIds"] as string[];
      }

      throw new Error(`Cannot resolve sub-identifiers using pid: ${parentId}`);
    });
  };

  const getRequestDetails = (id: string, receivedAuth?: string) => {
    let endpoint = `${process.env.VUE_APP_API_BASE}/r/${id}`;
    let headersObj = {};
    if (receivedAuth) {
      endpoint = `${process.env.VUE_APP_API_BASE}/o/r/${id}`;
      headersObj = {
        Authorization: `Bearer ${receivedAuth}`,
      };
    }
    return http
      .get(endpoint, { headers: headersObj })
      .then((response) => {
        const data = response.data;
        if (!data["aa"]) {
          data["aa"] = [
            {
              id: "finvu-id",
              handle: "@finvu",
              logo: "https://finvu.in/cdn/finvu_logo_svg.svg",
            },
          ];
        }
        // data["brandInfo"] = {
        //   "color": "#FF5732"
        // }
        return response.data;
      });
  };

  const getOrgDetails = (id: string) => {
    return http.get(`${process.env.VUE_APP_API_BASE}/r/org-info/${id}`);
  };

  const getBulkFileuPload = async (
    id: string,
    input: [],
    authValue: string,
  ) => {
    let headersObj = {};
    if (authValue != null) {
      headersObj = {
        Authorization: `Bearer ${authValue}`,
      };
    }
    return await http.post(
      `${process.env.VUE_APP_DOC_BASE}/document/o/uploadBulk/${id}`,
      input, {
        headers: headersObj,
      },
    );
  };
  const getUserConsentedAccounts = async (requestId: string, authValue: string) => {
    let headersObj = {};
    if (authValue != null) {
      headersObj = {
        Authorization: `Bearer ${authValue}`,
      };
    }
    // https://api.finarkein.in/cfm/v1/consent/accounts
    return await http.get(
      `${process.env.VUE_APP_API_BASE}/o/consent/accounts/${requestId}?page=0&size=1000`,
      {
        headers: headersObj,
      },
    );
  };
  const getParentWebviewDetails = async (id: string,
    inputs = {},
    tenant?: string,
    redirectTo?: string,
    receivedAuth?: string) => {
    let backUrl = "";
    let backRoute;
    backRoute = router.resolve({
      name: "v2u.landing",
      params: { identifier: id },
    });
    if (redirectTo) {
      backRoute = router.resolve({
        name: "mv2.landing",
        params: { identifier: id },
      });
    }
    backUrl = new URL(backRoute.href, window.location.origin).href;
    // console.debug("[api] resolved redirection callback url to: " + backUrl);
    let headersObj = {};
    if (receivedAuth != null) {
      headersObj = {
        Authorization: `Bearer ${receivedAuth}`,
      };
    }
    // {{CFM_BASE_URL}}/o/pr/:requestId/webview
    const data = {
      redirectUrl: backUrl,
      // redirectUrl: 'http://localhost:4400/o/bfl/thank-you',
      ...inputs, // override with any inputs supplied
    };
    return await http.post(
      `${process.env.VUE_APP_API_BASE}/o/pr/${id}/webview`,
      data,
      {
        headers: headersObj,
      },
    );
  };

  const getUserLinkedAccounts = async (aaSdk: AaWebSdkController) => {
    // commicate with BE and AASDK to get the response
    return aaSdk.getLinkedAccounts();
  };
  const getOTPDetailsForAALogin = async (
    aaSdk: AaWebSdkController,
    request: Partial<AuthInit>,
  ) => {
    // initiate the request by passing the selected bank , mobile number and AA handle after selecting the bank
    // selected bank detail, mobile number, aahandle in request
    return await aaSdk.authInit(request);
  };

  const verifyOTPForAALogin = async (
    aaSdk: AaWebSdkController,
    request: AuthVerify,
  ) => {
    return aaSdk.authVerify(request);
  };

  const allowAccess = async (
    aasdk: AaWebSdkController,
    request: ConsentRequest,
  ) => {
    return aasdk.consent(request);
  };

  const initiateConsent = async (
    aasdk: AaWebSdkController,
    consentHandle: string,
  ) => {
    return aasdk.consentRequestDetails(consentHandle);
  };

  const initiateAccountDiscovery = async (
    aasdk: AaWebSdkController,
    request: AccountDiscoveryRequest,
  ) => {
    return aasdk.discoverAccounts(request);
  };

  const initiateAccountLinking = async (
    aasdk: AaWebSdkController,
    request: AccountLinkingRequest,
  ) => {
    return aasdk.linkAccounts(request);
  };

  const confirmAccountLinking = async (
    aasdk: AaWebSdkController,
    request: ConfirmLinkingRequest,
  ) => {
    return aasdk.confirmLinking(request);
  };

  const initiateMobileAuth = async (
    aaSdk: AaWebSdkController,
    request: MobileAuthInit,
  ) => {
    return aaSdk.mobileAuthInit(request);
  };

  const confirmMobileAuth = async (
    aaSdk: AaWebSdkController,
    request: MobileAuthVerify,
  ) => {
    return aaSdk.mobileAuthVerify(request);
  };

  const logout = async (aaSdk: AaWebSdkController) => {
    return aaSdk.logout();
  };

  const userInfo = async (aaSdk: AaWebSdkController) => {
    return aaSdk.getUserInfo();
  };

  const getAAFipMappings = () => {
    // const url = 'apiUrl';
    // return http.get(url);
    return new Promise((resolve) => {
      let data = {};
      if (process.env.NODE_ENV === "development") {
        data = {
          anumati: ["ACME-FIP"],
          finvu: ["fip@finbank"],
          onemoney: ["finsharebank"],
          nadl: ["BARB0KIMXXX"],
        };
      }

      resolve({
        data,
      });
    });
  };

  return {
    getWebviewDetails,
    getBankList,
    getChildRequestIds,
    getRequestDetails,
    getMarkCompleteDetails,

    getOrgDetails,
    getUserLinkedAccounts,
    getOTPDetailsForAALogin,
    verifyOTPForAALogin,
    allowAccess,
    initiateConsent,
    initiateAccountDiscovery,
    initiateAccountLinking,
    confirmAccountLinking,
    initiateMobileAuth,
    confirmMobileAuth,
    logout,
    getConsentData,
    createConsentRequest,
    getConsentStatus,
    sendStatusUpdates,
    userInfo,
    getResponseParams,
    getAAFipMappings,
    getBulkFileuPload,
    getUserConsentedAccounts,
    getParentWebviewDetails,
  };
}